import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Form } from 'antd';
import * as yup from 'yup';

import PublicLayout from '../layout/public-layout';
import { Button } from '../components';
import { FormElementChangeEvent, Input, Select } from '../components/forms';
import theme from '../theme';
import validateWithSchema from '../utils/yup/validateWithSchema';

const InternalContainer = styled.div`
  max-width: ${theme.screenLgMax};
  margin: 0 auto;

  @media (max-width: ${theme.screenLgMax}) {
    padding: 0 20px;
  }
`;

const HeaderBase = `
color: ${theme.colorsBlack};
letter-spacing: -0.4px;
line-height: 38px;
font-weight: bold;
`;

const Header = styled.h2`
  ${HeaderBase}
  font-size: 32px;
  padding-top: 40px;
  padding-bottom: 24px;
`;

const Description = styled.p`
  color: ${theme.colorsBlack};
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
  padding-bottom: 40px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 160px;

  @media (max-width: ${theme.screenLgMax}) {
    max-width: 100%;
  }
`;

export default function ReferStudent() {
  const [formData, setFormData] = useState<{
    contactEmail?: string;
    contactSubject?: string;
    contactQuestionType?: string;
    contactMessage?: string;
  }>({});
  const [formErrors, setFormErrors] = useState<Record<string, any>>({});

  const validationSchema = yup.object().shape({
    studentEmail: yup
      .string()
      .required('Email is required')
      .email('Please enter a valid email'),
    studentName: yup.string().required('Student name is required'),
    studentGrade: yup.string().required('Select student grade level'),
    name: yup.string().required('Provide your name'),
  });

  const handleSubmit = useCallback(
    async () => {
      const { errors } = await validateWithSchema(validationSchema, false, formData);

      setFormErrors(errors);

      if (errors && Object.keys(errors).length === 0) {
        // TODO: Send to admin's message center, different ticket
      }
    },
    [formData, validationSchema]
  );

  const handleOnChange = useCallback(({ name, value }: FormElementChangeEvent) => {
    if (name) setFormData(prevState => ({ ...prevState, [name]: value }));
  }, []);

  return (
    <PublicLayout seoTitle="Refer a Student">
      <InternalContainer>
        <Row justify="center" gutter={32}>
          <Col xs={24} lg={20}>
            <Header>Refer a Student</Header>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.
            </Description>
            <Form onFinish={handleSubmit} layout="vertical">
              <Row gutter={32}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="*Student Name"
                    validateStatus={formErrors['studentName'] ? 'error' : ''}
                    help={formErrors['studentName'] && formErrors['studentName'].message}
                  >
                    <Input name="studentName" />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                  <Form.Item
                    label="*Student Email"
                    validateStatus={formErrors['studentEmail'] ? 'error' : ''}
                    help={formErrors['studentEmail'] && formErrors['studentEmail'].message}
                  >
                    <Input name="studentEmail" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="*Student Grade Level"
                    validateStatus={formErrors['studentGrade'] ? 'error' : ''}
                    help={formErrors['studentGrade'] && formErrors['studentGrade'].message}
                  >
                    <Select name="studentGrade" placeholder="Select One" onChange={handleOnChange} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="*Your Name"
                    validateStatus={formErrors['name'] ? 'error' : ''}
                    help={formErrors['name'] && formErrors['name'].message}
                  >
                    <Input name="name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col xs={24} lg={4}>
                  <Form.Item>
                    <StyledButton type="primary-blue" htmlType="submit" block>
                      Submit
                    </StyledButton>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </InternalContainer>
    </PublicLayout>
  );
}
